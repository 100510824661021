/* ======================== Sangrah - Start ======================== */

.sangrah {
    padding: 10vmin 15vmin;
}

.page_heading h1 {
    color: #FF9400;
    font-size: 8vmin;
    text-align: center;
    margin-bottom: 10vmin;
}

.sangrah_image_container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    align-content: center;
}

.sangrah_image img {
    width: 38vmin;
    margin-right: 7vmin;
    margin-bottom: 7vmin;
    border: 5px solid #FF9400;
    border: 7px solid #FEEDE3;
    border-top-left-radius: 5vmin;
    border-bottom-right-radius: 5vmin;
}

/* ====== Media Quries ====== */

@media only screen and (max-width: 700px) {

    .sangrah {
        padding: 10vmin 7vmin;
    }

    .page_heading h1 {
        font-size: 9vmin;
    }

    .sangrah_image img {
        width: 100%;
        margin-right: 7vmin;
        margin-bottom: 10vmin;
    }
}

/* ======================== Sangrah - End ======================== */