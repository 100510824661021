/* =========================== Header - Start =========================== */

.header {
    width: 100%;
    height: 14vmin;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    align-content: center;
    background-color: #FF9400;
    padding: 0 15vmin;

    /* For Responsive Header */
    position: relative;
}

.header .logo img {
    width: 20vmin;
    cursor: pointer;
}

.header .navbar {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    align-content: center;
    width: auto;
}

.header .navbar .nav_links {
    width: 100%;
    list-style-type: none;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    justify-content: center;
    align-items: center;
    align-content: center;
}

.header .navbar .nav_link {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    align-content: center;
    transition: 0.3s;
    padding: 4vmin 0 2vmin 6vmin;
}

.header .navbar .nav_links .nav_link>a {
    width: 100%;
    text-decoration: none;
    color: white;
    font-size: 3vmin;
    font-weight: 400;
    letter-spacing: 0px;
}

.header .navbar .nav_links .nav_link>a:hover {
    border-bottom: 2px solid white;
}

.register-form {
    max-width: 400px;
    margin: 40px auto;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.register-form h2 {
    margin-bottom: 20px;
}

.register-form label {
    display: block;
    margin-bottom: 5px;
}

.register-form input[type="text"],
.register-form input[type="email"] {
    width: 100%;
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
}

.register-form button[type="submit"] {
    width: 100%;
    background-color: #007bff;
    color: #fff;
    padding: 10px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.register-form button[type="submit"]:hover {
    background-color: #0056b3;
}


/* ====== Toggle ====== */

.toggle {
    display: none;
    /* display: block; */
    position: absolute;
    right: 0;
}

.menu_icon {
    width: 45px;
    height: 45px;
    position: relative;
    cursor: pointer;
    /* border: 2.5px solid #FFFFFF; */
    border-radius: 10px;
    border-radius: 2px;
}

.menu_icon span {
    background-color: #FFFFFF;
    width: 50%;
    height: 2.5px;
    border-radius: 4px;
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    transition: transform 0.5s, width 0.5s, opacity 0.5s;
}

.menu_icon .top {
    transform: translate(-50%, -8px);
}

.menu_icon .end {
    transform: translate(-50%, 6px);
}

.active .top {
    transform: translate(-50%, -50%) rotate(-45deg);
}

.active .end {
    transform: translate(-50%, -50%) rotate(45deg);
}

.active .middle {
    width: 0;
    opacity: 0;
}


/* ====== Media Quries ====== */

@media (max-width: 700px) {
    .toggle {
        display: block;
        padding: 30px;
    }

    .header {
        width: 100%;
        height: 22vmin;
        padding: 0 4vmin;
        z-index: 4;
    }

    .header .logo {
        display: flex;
        margin-left: 2vmin;
        cursor: pointer;
    }

    .header .logo img {
        width: 30vmin;
    }

    .navbar {
        visibility: hidden;
        opacity: 0;
    }

    .active .navbar {
        width: 100%;
        padding: 6vmin 6vmin 8vmin 6vmin;
        flex-direction: column;
        align-items: center;
        background-color: #FF9400;
        transition: opacity 0.2s ease-in;
        position: absolute;
        visibility: visible;
        opacity: 1;
        top: 100%;
        left: 0;
        z-index: 4;
        border-bottom: 3px solid white;
    }

    .active .navbar .nav_links {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .active .navbar .nav_links .nav_link {
        display: block;
        height: 5vh;
        margin-right: 14vmin;
    }

    .active .navbar .nav_links .nav_link a {
        display: block;
        text-transform: capitalize;
        font-size: 4vmin;
        font-weight: 400;
    }

    .header .navbar .nav_links .donate>a {
        font-size: 4.2vmin;
        font-weight: 500;
        padding: 1.5vmin 2.5vmin;
    }
}

/* =========================== Header - End =========================== */