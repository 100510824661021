/* =========================== Mahadev Kognure Parichay - Start =========================== */

.parichay {
    width: 100%;
    padding: 6vmin 15vmin 15vmin 15vmin;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-content: center;
    background-color: white;
}

.content_heading h3 {
    font-size: 5.5vmin;
    font-weight: 600;
    text-transform: uppercase;
    padding-bottom: 2vmin;
    margin: 4vmin 0 2vmin 0;
    text-align: center;
    color: #FF9400;
   
}

.parichay_img img {
    width: 16vw;
    border-radius: 5px;
}


.parichay_content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-content: start;
}

.parichay .parichay_text p {
    margin-left: 7vmin;
    font-size: 2.5vmin;
    text-align: justify;
    color: rgb(60, 60, 60);
    font-weight: 300;
}

/* ====== Media Quries ====== */

@media only screen and (max-width: 700px) {
    .parichay {
        padding: 6vmin 7vmin 15vmin 7vmin;
    }

    .parichay .content_heading h3 {
        font-size: 7.5vmin;
    }

    .parichay .parichay_content {
        flex-direction: column-reverse;
    }


    .parichay .parichay_img img {
        width: 87vw;
    }

    .parichay .parichay_text p {
        margin-bottom: 10vmin;
        margin-left: 0vmin;
        font-size: 3.55vmin;
    }
}

/* =========================== Mahadev Kognure Parichay - End =========================== */