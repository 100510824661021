/* =========================== Footer - Start =========================== */

/* ====== Pre Footer ====== */

.footer .pre_footer {
    padding: 5vmin 15vmin;
    background-color: #F8FAE5;
    background-color: #FEEDE3;
    background-color: rgb(240, 240, 240);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    align-content: center;
    border-top: 1px solid #ccc;
}

.footer .pre_footer h4 {
    color: #00869c;
    color: #F5950A;
    margin-bottom: 3vmin;
    font-size: 3.2vmin;
    font-weight: 400;
    letter-spacing: 0.5px;
}

.footer .pre_footer p {
    color: lightslategray;
    margin: 0 0 1.5vmin 0;
    letter-spacing: 0.5px;
    font-size: 2.3vmin;
    font-weight: 300;
}

.footer .pre_footer p a {
    text-decoration: none;
    color: lightslategray;
    letter-spacing: 0.5px;
    font-size: 2.3vmin;
    font-weight: 300;
    transition: all 0.5s ease;
}

.footer .pre_footer p i {
    margin-right: 1vmin;
}

.footer .pre_footer p a:hover {
    color: black;

}

.footer .pre_footer .primary_school {
    width: 25%;
}

.footer .pre_footer .high_school {
    width: 25%;
}

.footer .pre_footer .contact {
    width: 25%;
}


/* ====== Main Footer ====== */

.footer .main_footer {
    text-align: center;
    /* border: 1px solid #00869c; */
    background-color: #FF9400;
    padding-bottom: 2vmin;
}

.footer .main_footer p {
    color: #FFFFFF;
    padding: 2.5vmin 0;
    font-size: 2vmin;
    letter-spacing: 1px;
    font-weight: 300;
}

.footer .main_footer p a {
    text-decoration: none;
    color: #F8FAE5;
    cursor: pointer;
}


/* ====== Media Quries ====== */

@media (max-width: 700px) {

    /* ====== Pre Footer ====== */

    .footer .pre_footer {
        align-items: center;
        padding: 8vmin;
        flex-direction: column;
    }

    .footer .pre_footer .quick_links {
        margin-bottom: 5vmin;
    }

    .footer .pre_footer h4 {
        margin-bottom: 1.2vmin;
        font-size: 4vmin;
        text-align: center;
    }

    .footer .pre_footer p {
        font-size: 3.1vmin;
        text-align: center;
    }

    .footer .pre_footer p a {
        font-size: 3.1vmin;
        text-align: center;
    }

    .footer .pre_footer .primary_school {
        width: 100%;
        margin-bottom: 4vmin;
    }

    .footer .pre_footer .high_school {
        width: 100%;
        margin-bottom: 4vmin;
    }

    .footer .pre_footer .contact {
        width: 100%;
        margin-bottom: 4vmin;
    }

    /* ====== Main Footer ====== */

    .footer .main_footer p {
        padding: 2.5vmin 5vmin;
        font-size: 3vmin;
    }
}

/* =========================== Footer - End =========================== */