/* =========================== Samaj Karya Details - Start =========================== */

.details_container {
    width: 100%;
    padding: 10vmin 15vmin 10vmin 15vmin;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-content: center;
}

.details_content {
    height: auto;
}

.details_content .page_heading h1 {
    color: #FF9400;
    font-size: 8vmin;
    text-align: center;
    margin-bottom: 10vmin;
}

.details_image {
    width: 100%;    
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-content: center;
    align-items: center;
}

.details_image img {
    width: 100%;
    margin-bottom: 5vmin;
    border-radius: 5px;
}

.details_text p {
    margin: 2vmin 0 4vmin 0;
    color: #757575;
    font-size: 2.5vmin;
    font-weight: 300;
    text-align: justify;
}

.details_text span {
    margin: 2vmin 0 4vmin 0;
    color: #757575;
    font-size: 2.5vmin;
    font-weight: 600;
    text-align: justify;
}

.back_btn a {
    text-decoration: none;
    text-transform: uppercase;
    background-color: #FF9400;
    color: #FFFFFF;
    width: auto;
    padding: 1.5vmin 4vmin;
    border-radius: 2px;
    font-size: 2.7vmin;
    text-align: center;
    transition: all 0.5s;

    width: 10rem;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-content: center;
    align-items: center;
    gap: 0.7rem;
}

.back_btn a:hover {
    box-shadow: 0 0 15px #FF9400;
    background-color: #fea62a;
}

/* ====== Media Quries ====== */

@media only screen and (max-width: 700px) {
    .details_container {
        padding: 10vmin 7vmin 10vmin 7vmin;
    }
    
    .details_content .page_heading h1 {
        font-size: 4vmin;
    }

    .back_btn a {
        width: 7rem;
    }
}

/* =========================== Samaj Karya Details - End =========================== */