.mkfoundation {
    width: 100%;
    padding: 6vmin 15vmin 20vmin 15vmin;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-content: center;
    background-color: white;
}

.mkfoundation .content_heading h3 {
    font-size: 5.5vmin;
    font-weight: 600;
    color: #FF9400;
    letter-spacing: 1px;
    text-transform: uppercase;
    padding-bottom: 2vmin;
    margin: 4vmin 0 2vmin 0;
    text-align: center;
}

.mkfoundation .mkfoundation_content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-content: start;
}

.mkfoundation img {
    width: 20vw;
    border-radius: 3vmin;
    margin-left: 6vmin;
    border: none;
}

.mkfoundation .mkfoundation_text p {
    font-size: 2.5vmin;
    text-align: justify;
    letter-spacing: 0.5px;
    color: rgb(60, 60, 60);
    font-weight: 300;
}

.mkfoundation .mkfoundation_content .know_more_btn a {
    text-decoration: none;
    text-transform: uppercase;
    background-color: #FF9400;
    color: #FFFFFF;
    padding: 1vmin 2vmin;
    text-align: center;
    border-radius: 2px;
    display: block;
    display: inline-block;
    margin: 1vmin 0;
    transition: all 0.5s;
    margin-bottom: 5rem;
}

.mkfoundation .mkfoundation_content .know_more_btn a:hover {
    box-shadow: 0 0 15px #FF9400;
    background-color: #fea62a;
}

.mkfoundation .mkfoundation_text span {
    font-size: 3.2vmin;
    font-weight: 700;
}

@media only screen and (max-width: 700px) {
    .mkfoundation {
        padding: 6vmin 7vmin 0vmin 7vmin;
        flex-direction: column;
    }

    .mkfoundation .content_heading h3 {
        font-size: 7.5vmin;
        margin-bottom: 3rem;
    }

    .mkfoundation .mkfoundation_content {
        flex-direction: column-reverse;
    }

    .mkfoundation .mk_foundation_logo img {
        width: 85vw;
        margin: 0 0 3rem 0;
    }

    .mkfoundation .mkfoundation_text p {
        margin-bottom: 10vmin;
        margin-left: 0vmin;
        font-size: 3.55vmin;
    }
}