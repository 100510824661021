/* =========================== Typography =========================== */

@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lexend:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Playwrite+NG+Modern:wght@100..400&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Hind:wght@300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Hind:wght@300;400;500;600;700&family=Tiro+Devanagari+Marathi:ital@0;1&display=swap');

* {
    margin: 0;
    padding: 0;
    font-family: 'Outfit', sans-serif;
    font-family: "Hind", sans-serif;
    /* font-family: "Tiro Devanagari Marathi", serif; */
    box-sizing: border-box;
}

body::-webkit-scrollbar {
    display: none;
}

body,
html {
    overflow-x: hidden;
}

/* ================================================================== */




/* =========================== Form Styling - Start =========================== */

.form_container {
    background-color: #FFFFFF;
    padding: 10vmin;
    border-radius: 2vmin;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    margin: 4vmin 0;
}

.form_container h2 {
    font-size: 5vmin;
    color: #FF9400;
    margin-bottom: 2vmin;
    text-transform: uppercase;
}

.form_container form {
    display: flex;
    flex-direction: column;
}

.form_container form .form_group {
    margin-bottom: 2vmin;
}

.form_container form .form_group label {
    font-size: 2.5vmin;
    color: #808080;
    margin-bottom: 0.5vmin;
    display: block;
}

.form_container form .form_group input,
.form_container form .form_group textarea {
    font-size: 2.5vmin;
    padding: 1vmin;
    border: 1px solid #ccc;
    border-radius: 2px;
    width: 100%;
    box-sizing: border-box;
}

.form_container form .form_group textarea {
    resize: vertical;
    height: 15vmin;
}

.form_container form .form_submit_btn {
    background-color: #FF9400;
    color: #FFFFFF;
    padding: 1.5vmin 2vmin;
    border: none;
    border-radius: 2px;
    font-size: 2.5vmin;
    text-transform: uppercase;
    cursor: pointer;
    transition: all 0.3s;
}

.form_container form .form_submit_btn:hover {
    background-color: #fea62a;
    box-shadow: 0 0 10px #FF9400;
}

@media only screen and (max-width: 700px) {
    .form_container {
        padding: 5vmin;
    }

    .form_container h2 {
        font-size: 6vmin;
    }

    .form_container form .form_group label,
    .form_container form .form_group input,
    .form_container form .form_group textarea,
    .form_container form .form_submit_btn {
        font-size: 3vmin;
    }
}

/* =========================== Form Styling - End =========================== */


/* =========================== Additional Content Styling - Start =========================== */

.additional_content {
    background-color: #FFFFFF;
    padding: 5vmin;
    border-radius: 2vmin;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    margin: 4vmin 0;
    text-align: justify;
}

.additional_content h3 {
    font-size: 4.5vmin;
    color: #FF9400;
    margin-bottom: 2vmin;
    text-align: center;
}

.additional_content p {
    font-size: 2.5vmin;
    color: #808080;
}

/* =========================== Additional Content Styling - End =========================== */