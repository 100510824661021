:root {
  --primary-color: #3498db;
  --secondary-color: #2ecc71;
  --text-color: #333;
  --background-color: #f9f9f9;
  --border-color: #ddd;
  --border-radius: 0.5rem;
  --padding: 1rem;
  --margin: 1rem;
}

body {
  font-family: Arial, sans-serif;
  background-color: var(--background-color);
  color: var(--text-color);
}

.admin_panel_page {
  display: flex;
  flex-direction: column;
  padding: var(--padding);
}

.admin_panel_page__header {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: var(--margin);
}

.menu {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  justify-content: center;
}

.menu button {
  background: var(--primary-color);
  color: #fff;
  border: none;
  padding: 0.5rem 1rem;
  border-radius: var(--border-radius);
  cursor: pointer;
}

.menu button:hover {
  background: var(--secondary-color);
}

.admin_panel_page__content {
  display: flex;
  flex-direction: column;
  gap: var(--margin);
}

.admin_panel_page__form {
  background: #fff;
  padding: var(--padding);
  border: 1px solid var(--border-color);
  border-radius: var(--border-radius);
}

.admin_panel_page__form h2 {
  margin-bottom: var(--margin);
}

.form_group {
  margin-bottom: var(--margin);
}

.form_group label {
  display: block;
  margin-bottom: 0.5rem;
}

.form_group input,
.form_group textarea {
  width: 100%;
  padding: 0.5rem;
  border: 1px solid var(--border-color);
  border-radius: var(--border-radius);
}

.form_group textarea {
  height: auto;
  resize: none;
}

.admin_panel_page__form button {
  background: var(--primary-color);
  color: #fff;
  border: none;
  padding: 0.5rem 1rem;
  margin-right: 0.5rem;
  border-radius: var(--border-radius);
  cursor: pointer;
}

.admin_panel_page__form button:hover {
  background: var(--secondary-color);
}

.admin_panel_page__table {
  background: #fff;
  padding: var(--padding);
  border: 1px solid var(--border-color);
  border-radius: var(--border-radius);
}

.admin_panel_page__table h2 {
  margin-bottom: var(--margin);
}

table {
  width: 100%;
  border-collapse: collapse;
}

table th,
table td {
  padding: 0.75rem;
  text-align: left;
  border-bottom: 1px solid var(--border-color);
}

table th {
  background: var(--primary-color);
  color: #fff;
}

table tr:hover {
  background: var(--background-color);
}

table button {
  background: var(--primary-color);
  color: #fff;
  border: none;
  padding: 0.5rem 1rem;
  border-radius: var(--border-radius);
  cursor: pointer;
  margin-right: 0.5rem;
}

table button:hover {
  background: var(--secondary-color);
}

@media (max-width: 768px) {
  .admin_panel_page {
    padding: 0.5rem;
  }

  .admin_panel_page__header {
    flex-direction: column;
    align-items: center;
  }

  .menu {
    flex-direction: column;
    align-items: center;
  }

  .menu button {
    width: 100%;
    margin: 0.5rem 0;
  }

  .admin_panel_page__content {
    flex-direction: column;
  }

  .admin_panel_page__form,
  .admin_panel_page__table {
    padding: 0.5rem;
    width: 100%;
  }

  table {
    font-size: 0.875rem;
  }

  table th,
  table td {
    padding: 0.5rem;
  }
}

@media (max-width: 480px) {
  .admin_panel_page__header h1 {
    font-size: 1.5rem;
  }

  .admin_panel_page__form button {
    width: 100%;
    margin: 0.5rem 0;
  }

  table button {
    width: 100%;
    margin: 0.5rem 0;
  }

  .menu {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 0.5rem;
    margin-top: var(--margin);
  }

  .menu button {
    padding: 1rem;
    font-size: 1rem;
    text-align: center;
  }

  .table-container {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    margin: 0 -16px;
    padding: 0 16px;
  }

  .table {
    min-width: 600px;
    border-collapse: collapse;
  }

  .table th,
  .table td {
    padding: 8px;
    text-align: left;
    border: 1px solid #ddd;
  }
}