/* =========================== Manyavaranchi Mate - Start =========================== */

.manyavaranchi_mate {
    width: 100%;
    padding: 10vmin 15vmin 10vmin 15vmin;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-content: center;
}

.page_heading h1 {
    color: #FF9400;
    font-size: 8vmin;
    text-align: center;
    margin-bottom: 10vmin;
}

.feedback {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-content: center;
    background-color: #F0F0F0;
    margin-bottom: 15vmin;
    padding: 7vmin 10vmin;
    border-top: 4px solid #FF9400;
    border-bottom: 4px solid #FF9400;
}

.feedback h6 {
    font-size: 3.8vmin;
    font-weight: 600;
    color: #FF9400;
    color: gray;
    letter-spacing: 1px;
    padding-bottom: 1vmin;
    text-transform: uppercase;
    text-align: left;
}

.feedback .reviewers_feedback p {
    color: rgb(60, 60, 60);
    font-size: 2.5vmin;
    font-weight: 300;
    letter-spacing: 0.5px;
    text-align: justify;
}

/* ====== Media Quries ====== */

@media only screen and (max-width: 700px) {

    .manyavaranchi_mate {
        padding: 10vmin 7vmin;
    }

    .page_heading h1 {
        font-size: 9vmin;
    }

    .feedback {
        padding: 7vmin 7vmin;
    }

    .feedback h6 {
        font-size: 5vmin;
    }

    .feedback .reviewers_feedback p {
        font-size: 3.4vmin;
    }
}

/* =========================== Manyavaranchi Mate - End =========================== */