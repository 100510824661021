.admin_login_page {
    height: 100vh;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    align-content: center;
    padding: 1rem;
}

.admin_login_form {
    width: 100%;
    max-width: 400px;
    height: auto;
    padding: 2rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    align-items: start;
    border: 1px solid #ccc;
    border-radius: 0.4rem;
    background-color: #fff;
}

.form_heading {
    width: 100%;
    text-align: center;
    margin-bottom: 2rem;
}

.email_input {
    margin-bottom: 1.5rem;
}

.password_input {
    margin-bottom: 1.5rem;
}

.login_button {
    width: 100%;
    margin-bottom: 2rem;
}

.login_failed_alert {
    border-radius: 0.3rem;
}

/* Media Queries for Mobile View */
@media (max-width: 600px) {
    .admin_login_page {
        padding: 1rem;
    }

    .admin_login_form {
        width: 100%;
        max-width: none;
        padding: 1.5rem;
    }

    .form_heading {
        margin-bottom: 1.5rem;
    }

    .login_button {
        margin-bottom: 1.5rem;
    }
}
