/* =========================== Samaj Karya - Start =========================== */

.samaj_karye {
    width: 100%;
    padding: 10vmin 15vmin 10vmin 15vmin;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-content: center;
}

.page_heading h1 {
    color: #FF9400;
    font-size: 8vmin;
    text-align: center;
    margin-bottom: 10vmin;
}

.activity {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
    background-color: rgb(240, 240, 240);
    padding: 7vmin;
    margin-bottom: 12vmin;
    border-radius: 2vmin;
}

.activity_image img {
    width: 25vw;
    border-radius: 10px;
}

.activity_content {
    margin-left: 8vmin;
}

.activity_content h1 {
    font-size: 5.5vmin;
    font-weight: 600;
    color: #FF9400;
    letter-spacing: 1px;
}

.activity_content p {
    margin: 2vmin 0 4vmin 0;
    color: #757575;
    font-size: 2.5vmin;
    font-weight: 300;
    text-align: justify;
}

.know_more_button a {
    text-decoration: none;
    text-transform: uppercase;
    background-color: #FF9400;
    color: #FFFFFF;
    width: auto;
    padding: 1vmin 4vmin;
    border-radius: 2px;
    font-size: 2.4vmin;
    text-align: center;
    transition: all 0.5s;
}

.know_more_button a:hover {
    box-shadow: 0 0 15px #FF9400;
    background-color: #fea62a;
}

/* ====== Media Quries ====== */

@media only screen and (max-width: 700px) {
    .samaj_karye {
        padding: 10vmin 7vmin;
    }
    
    .page_heading h1 {
        font-size: 9vmin;
    }

    .activity {
        padding: 6vmin;
        margin-bottom: 10vmin;
        flex-direction: column;
    }

    .activity_image img {
        width: 100%;
        border-radius: 10px;
        margin-bottom: 8vmin;
    }

    .activity_content {
        margin-left: 0vmin;
    }

    .activity_content h1 {
        font-size: 5.5vmin;
        text-align: center;
    }

    .activity_content p {
        margin: 3vmin 0 6vmin 0;
        color: #808080;
        font-size: 3.3vmin;
        font-weight: 300;
        letter-spacing: 0.3px;
        text-align: justify;
    }

    .know_more_button a {
        padding: 1.5vmin 5vmin;
        font-size: 3.5vmin;
    }
}

/* =========================== Samaj Karya - End =========================== */
